import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from '@ngx-translate/core';

import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';


import * as _ from 'lodash';
import {RouterDataService} from '../../../shared/services/router-data.service';
import * as moment from 'moment';
import {DailyWorkflowService} from '../../services/daily-workflow.service';
import {BILLING_REQUEST_VIEW} from '../../utils/views-settings';
import {TableColumnDefinition, TableQuery} from '@ft/table/table/models/table-definitions';
import {DefaultValuesService, FtWsService, LARGE_DIALOG} from '@ft/core';
import {AddRequestDialogComponent} from "../../dialogs/add-request-dialog/add-request-dialog.component";
import {DEFAULT_FILTERS} from "../../../shared/utils/default-conts";
import {BillingService} from "../../../shared/services/billing.service";
import {PaymentService} from "../../../payment/services/payment.service";

@Component({
  selector: 'ftb-billing-request-list',
  templateUrl: './billing-request-list.component.html',
  styleUrls: ['./billing-request-list.component.scss']
})
export class BillingRequestListComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  amountsColumns: TableColumnDefinition[] = [
    {
      'label': 'dailyWorkflow.taxed_amount',
      'key': 'taxed_amount',
      'sortBy': 'taxed_amount',
      'type': 'number',
      'numberConfig': 'currency',
      'is_global_searchable': true,
      footer: () => {
        return this.footerData['taxed_amount']
      }
    },
    {
      'label': 'dailyWorkflow.received_amount',
      'type': 'number',
      'key': 'paid_amount',
      'sortBy': 'paid_amount',
      'numberConfig': 'currency',
      'is_global_searchable': false,
      footer: () => {
        return this.footerData['paid_amount']
      }
    },
    {
      'label': 'payment.line_remaining_amount',
      'type': 'number',
      'key': 'remaining_amount',
      'numberConfig': 'currency',
      'is_global_searchable': false,
      footer: () => {
        return this.footerData['taxed_amount'] > this.footerData['paid_amount'] ? this.footerData['taxed_amount'] - this.footerData['paid_amount'] - this.footerData['exempted_amount'] : 0
      }
    }]
  viewSettings = _.chain(BILLING_REQUEST_VIEW).cloneDeep().assignIn({
    query: [{
      key: 'doc_date',
      logical_operator: 'and',
      operator: '$eq',
      value: moment().format('YYYY-MM-DD')
    }]
  }).value();
  multiActions = [
    {
      class: 'mat-primary',
      icon: 'mdi-receipt-text',
      isMultipleSelection: true,
      method: (item, ev) => {
        return this.handleInvoice(item);

      },
      tooltip: 'shared.bill'
    },
    {
      class: 'mat-accent',
      icon: 'mdi-currency-usd',
      isMultipleSelection: true,
      method: (item, ev) => {
        return this.handlePayment(item);
      },
      tooltip: 'shared.pay'
    },
    {
      class: 'mat-accent',
      icon: 'mdi-timetable',
      isMultipleSelection: false,
      method: (item, ev) => {
        return this.handleNewBillingRequest(item);
      },
      tooltip: 'dailyWorkflow.schedule',
      acl: {resource: 'billing-request', action: 'create', behavior: 'remove'}

    }

  ];
  query: TableQuery[] = [
    {
      value: moment().format('YYYY-MM-DD'),
      operator: '$eq',
      logical_operator: 'and',
      key: 'created_at',
      type: 'date'
    }
  ];

  reloadEvent = new EventEmitter();
  defaultFilters = DEFAULT_FILTERS;
  lineClass = (row) => {
    return !_.get(row, 'doc_id') ? 'draft-background' : ''
  };

  footerData = {};
  dateQuery = {from_date: null, to_date: null};
  activeToggle = 'shared.today';
  prev_search = null;
  hideFooter = false;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    protected snackBar: MatSnackBar,
    private routerDataService: RouterDataService,
    private dailyWorkflowService: DailyWorkflowService,
    private ws: FtWsService,
    private dialog: MatDialog,
    private defaultValuesService: DefaultValuesService,
    private billingService: BillingService,
    private paymentService: PaymentService
  ) {
    this.viewSettings.viewSettings.viewColumns = this.viewSettings.viewSettings.viewColumns.concat(this.amountsColumns);
  }

  ngOnInit() {
    this.viewSettings.viewSettings.viewColumns.push({
      label: '', key: 'actions', type: 'actions', actions: [
        {
          class: 'mat-accent',
          icon: 'mdi-pencil',
          tooltip: 'shared.edit',
          method: (item, index, ev) => {
            return this.handleEdit(item);
          },
          acl: {resource: _.get(this.viewSettings, 'resource'), action: 'update', behavior: 'remove'},
        },
        {
          class: 'mat-primary',
          icon: 'mdi-receipt-text',
          tooltip: 'shared.bill',
          method: (item, index, ev) => {
            return this.handleInvoice(item);
          },
          acl: {resource: _.get(this.viewSettings, 'resource'), action: 'get', behavior: 'disabled'},
          disabled: (row) => {
            return _.get(row, 'fully_billed', true) || !_.get(row, 'doc_id');
          }
        },
        {
          class: 'mat-primary',
          icon: 'mdi-currency-usd',
          tooltip: 'shared.pay',
          method: (item, index, ev) => {
            return this.handlePayment(item);
          },
          acl: {resource: _.get(this.viewSettings, 'resource'), action: 'create', behavior: 'remove'},
          disabled: (row) => {
            return _.get(row, 'is_paid') || !_.get(row, 'doc_id');
          }
        },
        {
          class: 'mat-warn',
          icon: 'mdi-currency-usd-off',
          tooltip: 'dailyWorkflow.fence_payment',
          method: (item, index, ev) => {
            return this.handlePaymentClose(item);
          },
          acl: {resource: _.get(this.viewSettings, 'resource'), action: 'create', behavior: 'remove'},
          disabled: (row) => {
            return _.get(row, 'remaining_amount') == 0;
          }
        },
        {
          class: 'mat-warn',
          icon: 'mdi-currency-php',
          tooltip: 'dailyWorkflow.cancel_payment_close',
          method: (item, index, ev) => {
            return this.handleCancelPaymentClose(item);
          },
          acl: {resource: _.get(this.viewSettings, 'resource'), action: 'create', behavior: 'remove'},
          disabled: (row) => {
            return _.get(row, 'fenced_amount') == 0;
          }
        }
      ]
    });

    let idx = _.findIndex(this.viewSettings.viewSettings.viewColumns, (e) => {
      return _.get(e, 'key') == 'debtor'
    });
    this.viewSettings.viewSettings.viewColumns[idx].style = (row) => {
      return {'background-color': this.defaultValuesService.getValue(`${_.get(row, 'debtor')}_color`)}
    }


    this.subscription = this.ws.observe('billing-request.handle_refresh').subscribe(data => {
        if (data) this.reloadEvent.emit(true);
      }
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  addClick() {
  }

  handleInvoice(data) {
    let lines = [];
    let patient = null;
    let organization = null;
    let convention = null;
    let physician = null;

    let docs = [];

    if (Array.isArray(data) && !_.isEmpty(data)) {
      if (_.every(data, {patient: data[0].patient})) {
        convention = _.get(data[0], 'financial_detail.convention')
        physician = _.get(data[0], 'external_physician')
        patient = data[0].patient || null;
      }
      if (_.every(data, {organization: data[0].organization})) {
        convention = _.get(data[0], 'financial_detail.convention')
        physician = _.get(data[0], 'external_physician')
        organization = data[0].organization || null;
      }
      docs = _.chain(data).filter((item) => {
        return !_.get(item, 'fully_billed', true);
      }).map((e) => {
        return {
          cls: 'billing-request',
          pk: _.get(e, 'id'),
          description: `${this.translateService.instant(`shared.patientVisits`)}
          ${this.translateService.instant('shared.nr')}:
          ${e.doc_id} - ${moment(e['doc_date']).format('DD/MM/YYYY')}`
        }
      }).value()

    } else {
      if (!_.get(data, 'fully_billed', true)) {
        patient = data.patient || null;
        organization = data.organization || null;
        convention = _.get(data, 'financial_detail.convention');
        physician = _.get(data, 'external_physician');
        docs.push({
          cls: 'billing-request',
          pk: _.get(data, 'id'),
          description: `${this.translateService.instant(`shared.patientVisits`)}
          ${this.translateService.instant('shared.nr')}:
          ${data.doc_id} - ${moment(data['doc_date']).format('DD/MM/YYYY')}`

        })
      }
    }
    if (!_.isEmpty(docs)) {
      this.billingService.handleNonBilledLines(docs).subscribe(data => {
        _.forEach(data, (d, i) => {
          lines.push({
            description: docs[i].description,
            billingRequest: {id: docs[i].pk},
            details: _.map(data[i], (obj) => {
              return {
                procedure: obj.procedure,
                code: obj.procedure.code,
                description: obj.procedure.name,
                price: _.get(obj, 'price', 0),
                qte: obj.non_billed_qte,
                discount: obj.discount || 0,
                discount_amount_from_global: _.get(obj, 'discount_amount_from_global', 0),
                discount_from_global: _.get(obj, 'discount_from_global', 0),
                source_qte: obj.qte,
                tariff: obj.tariff || {tp: 0, tm: 100}
              };
            })
          });
        })
        this.routerDataService.routerData = {
          invoiceData: {
            lines,
            patient,
            organization,
            convention,
            physician,
            pricing: _.get(organization, 'pricing')
          }
        };
        this.router.navigate(['/invoicing/invoice/invoice-form/']);

      }, error => {
        console.log('err---------------', error)
      });
    }
  }

  handlePaymentLine(_pkg, _model, doc) {
    return {
      total_amount: doc.taxed_amount,
      paid_doc: _.assignIn(doc, {_module: _pkg, _model: _model}),
      encasement_amount: 0.0,
      remaining_amount: doc.remaining_amount
    };

  }

  handlePayment(data) {
    let paymentLines = [];
    let beneficiary = null;
    let patient = null
    let organization = null;
    let received_amount = 0;
    let beneficiary_type = null;
    if (Array.isArray(data) && !_.isEmpty(data)) {
      _.chain(data).filter((item) => {
        return !item['is_paid'];
      }).value().forEach((e) => {
        paymentLines.push(this.handlePaymentLine('request_flow.models', 'BillingRequest', e));
      });
      if (_.every(data, {patient: data[0].patient})) {

        patient = data[0].patient;
        beneficiary_type = 'patient';
      }
      if (_.every(data, {organization: data[0].organization})) {
        organization = data[0].organization;
        beneficiary_type = 'organization';
      }
    } else {
      if (!data['is_paid']) {
        received_amount = _.get(data, 'remaining_amount');
        paymentLines.push(this.handlePaymentLine('request_flow.models', 'BillingRequest', data));
        beneficiary_type = data.patient ? 'patient' : 'organization';
        if (data.patient || data.organization) {
          if (beneficiary_type === 'patient') {
            patient = data.patient
          } else {
            organization = data.organization;
          }
        }
      }
    }
    if (!_.isEmpty(paymentLines)) {

      this.routerDataService.routerData = {
        paymentData: {
          paymentLines,
          beneficiary_type,
          patient,
          organization,
          payer_type: beneficiary_type,
          received_amount
        }
      };
      this.router.navigate(['/payment/payments/payment-form/']);
    }
  }

  handleAuthorization(data) {
    this.dailyWorkflowService.handleAuthorization(data.id).subscribe(data => _.noop());
  }

  handleEdit(item) {
    this.dialog.open(AddRequestDialogComponent, _.assignIn(LARGE_DIALOG, {
      data: {
        pk: _.get(item, 'id')
      },
      disableClose: true
    })).afterClosed().subscribe(res => {
      if (res) {
        this.reloadEvent.emit(true);
      }
    });
  }

  handleFilter(ev) {
    this.activeToggle = _.get(ev, 'value.label');
    this.query = _.get(ev, 'value.query');
    this.dateQuery = {from_date: null, to_date: null};
  }

  handleNewBillingRequest(data) {
    this.dialog.open(AddRequestDialogComponent, _.assignIn(LARGE_DIALOG, {
      data: {
        // model: _.cloneDeep(_.pick(item, 'global_discount', 'global_discount_amount', 'procedures')),
        // pk: _.get(item, 'id'),
        // full_name: _.get(item, 'patient.full_name'),
        // payment_status: _.get(item, 'payment_status')
      },
      disableClose: true
    })).afterClosed().subscribe(res => {
      if (res) {
        this.reloadEvent.emit(true);
      }
    });
  }


  handleViewQueryChange(value) {
    if (_.get(value, 'search_all.search', '') != '') {
      this.hideFooter = true;
    }else{
    if (this.prev_search != '' && _.get(value, 'search_all.search') == '') {
      this.hideFooter = true;
      this.dailyWorkflowService.handleBillingRequestFooter(value).pipe(debounceTime(250)).subscribe(data => {
        this.hideFooter = false;
        if (data) {
          this.footerData = data;
        }
      });
    } else {
      if ((_.isNil(this.prev_search) && !_.has(value, 'search_all.search')) ||  (this.prev_search=='' &&  _.get(value, 'search_all.search') == '')) {
        this.hideFooter = false;
        this.dailyWorkflowService.handleBillingRequestFooter(value).subscribe(data => {
          if (data) {
            this.footerData = data;
          }
        });
      }
    }}
    this.prev_search = _.get(value, 'search_all.search');
  }

  handleDateQuery(ev, name) {
    this.dateQuery[name] = ev;
    if (this.dateQuery.from_date && this.dateQuery.to_date) {
      this.query = [{
        value: moment(this.dateQuery.from_date).format('YYYY-MM-DD'),
        operator: '$gte',
        logical_operator: 'and',
        key: 'request_date_time',
        type: 'date'
      },
        {
          value: moment(this.dateQuery.to_date).add("days", 1).format('YYYY-MM-DD'),
          operator: '$lte',
          logical_operator: 'and',
          key: 'request_date_time',
          type: 'date'
        }];
    }
  }

  handlePaymentClose(item) {
    this.dailyWorkflowService.handlePaymentClose({pk: item.id, cancel: false}).subscribe(data => {
      this.snackBar.open(this.translateService.instant('dailyWorkflow.fenced_payment_message_success'), null, {
        duration: 1500,
        horizontalPosition: 'left',
      });
    });
  }

  handleCancelPaymentClose(item) {
    this.dailyWorkflowService.handlePaymentClose({pk: item.id, cancel: true}).subscribe(data => {
      this.snackBar.open(this.translateService.instant('dailyWorkflow.cancel_payment_close_success'), null, {
        duration: 1500,
        horizontalPosition: 'left',
      });
    });
  }
}
